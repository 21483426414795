// import './localStorage';

// application path
export const pathname = window.document.location.pathname;

// storage prefix
export const prefix = pathname.match(/^\/d\//)
  ? 'D'
  : pathname.match(/^\/x\//)
  ? 'B'
  : 'F';

// storage key
export const key = 'token';

// native data functions
const du = (x) => window.decodeURIComponent(x);
const eu = (x) => window.encodeURIComponent(x);
const sg = (x) => window.localStorage.getItem(x);
const ss = (x, v) => window.localStorage.setItem(x, v);

// getJwtStore function
export const getJwtStore = (w) => {
  const A = w || prefix;
  const s = sg(A + key) || sg(key);
  // console.log('getJwtStore',{func:'getJwtStore',A,c,key,s });
  return s;
};

// setJwtStorage function
export const setJwtStore = (w, v) => {
  const A = w || prefix;
  // console.log('setJwtStore',{func:'setJwtStore',A,prefix, v });
  ss(A + key, v);
};

// getJwtCookie function
export const getJwtCookie = (w) => {
  const A = w || prefix;
  const c = getCookieWithoutSignature;
  const k = c(A + 'v');
  const s = k && c(A + k + 'jwt');
  // console.log('getStateCookie',{func:'getStateCookie',A,c,k,s });
  return s;
};

// getJwtString function
export const getJwtString = (w) => {
  const A = w || prefix;
  const s = getJwtStore(A) || getJwtCookie(A);
  // console.log('getJwtString',{func:'getJwtString',A,s });
  return s;
};

// getJwtState function
export const getJwtState = (w) => {
  const A = w || prefix;
  const s = getJwtString(A);
  const x = s && parseJwt(s);
  // console.log('getJwtState',{func:'getJwtState',A,s,x });
  return x;
};

// jwt token parser without native browser atob function
// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJwt1 = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = du(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  // console.log('parseJwt1',{func:'parseJwt1',base64Url,base64,jsonPayload });
  return JSON.parse(jsonPayload);
};
// jwt token parser without native browser atob function
export const parseJwt2 = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
export const parseJwt = (token) => {
  // return parseJwt1(token);
  return parseJwt2(token);
};
// parseJwt(localStorage.getItem("token")).u

// #TODO: don't just parse, add basic validation
export const getString = () => getJwtString(prefix) || '';
export const getToken = () => getJwtState(prefix) || {};
export const setToken = (v) => setJwtStore(prefix, v);
export const clearToken = () => setToken('');

// export
export default {
  string: getString,
  json: getToken,
  get: getToken,
  set: setToken,
  clear: clearToken,
};

// old cookie integration, fallback
export const getCookie = (n) => {
  const name = eu(n);
  // console.log('getCookie', n, name);
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return du(parts.pop().split(';').shift());
};

export const getCookieWithoutSignature = (n) =>
  (getCookie(n) || '').replace(/@[^@]+$/, '');

// getStateCookie function
export const getStateCookie = (w) => {
  const // z = du,
    A = w || prefix;
  // cs = document.cookie.split(/;\s*/gi),
  const c = getCookieWithoutSignature; // (n) => cs.filter(function(c,i){ /*console.log('getStateCookie filter',i,c); */ return c && String(z(c)).match(RegExp('^'+n+'\=','gi')) }).map((c)=>{ return z(c.split('=')[1]).replace(/\@[^@]+$/,'') })[0],
  const k = c(A + 'v');
  const s = k && c(A + k);
  /*
  console.log({func:'getStateCookie',A,cs,c,k,s });
  */
  return s;
};

// getState function
export const getState = (w) => {
  const z = du;
  const A = w || prefix;
  const o = (a) => {
    const s = {};
    for (let i = 0; i < a.length; i++) {
      const x = a[i].split('=');
      s[x[0]] = z(x[1]).replace('+', ' ');
    }
    return s;
  };
  const s = getStateCookie(A);
  const a = s && s.split('&');
  const x = a && o(a);
  // console.log('getState', {func:'getState',A,o,s,a,x});
  return x;
};
