import { ParseContext, EnvironmentConstants } from './context'; // '@fyne/ui/context';
console.log('FFS process.env before;', { env: process.env });
require('dotenv').config();
console.log('FFS process.env after;', { env: process.env });
const context = ParseContext(process.env);
console.log('FFS const context = ParseContext(process.env);', { context });
const constants = EnvironmentConstants(process.env);
console.log('FFS const constants = EnvironmentConstants(process.env);', {
  constants,
});
const baseUrl =
  context.API_BASE || 'https://' + context.domain + (context.path || '/x/api');

console.log('Config', {
  baseUrl,
  env: process.env,
  constants,
  context,
  parsed: ParseContext(process.env),
});

export const Config = {
  ...context,
  baseUrl,
  // "client": "dashboard",
  // "apikey": "jwt?",
  // "domain": "domain.com"
};

export default Config;
