import React, { forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { installPromptIsAvailable as check, installPrompt } from 'lib/install';
import { useTranslate, MenuItemLink } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
export const installPromptIsAvailable = () => check();

export const fireInstallPrompt = () => {
  installPrompt()
    .then((userChoice) => console.log('InstallPrompt success', userChoice))
    .catch((userChoice) => console.log('InstallPrompt ignored', userChoice));
};

// eslint-disable-next-line react/display-name
export const InstallPromptMenuItem = forwardRef(
  ({ onClick: closeMenuOnClick }, ref) => {
    const translate = useTranslate();
    if (!installPromptIsAvailable()) {
      // console.warn("Install prompt was not available", {installPrompt,deferredPrompt:window.deferredPrompt});
      return null;
    }
    return (
      <MenuItemLink
        ref={ref}
        to={`/install`}
        primaryText={translate('ra.menu.install', { _: 'Install' })}
        leftIcon={<GetAppIcon />}
        onClick={() => {
          fireInstallPrompt();
          closeMenuOnClick();
        }} // close the menu on click
      />
    );
  }
);

export const InstallPromptButton = () => {
  if (!installPromptIsAvailable()) {
    // console.warn("Install prompt was not available", {installPrompt,deferredPrompt:window.deferredPrompt});
    return null;
  }

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="install-prompt-available"
      style={{
        display: !window.deferredPrompt ? 'none' : 'block',
      }}
    >
      {' '}
      <Button variant="contained" color="primary" onClick={fireInstallPrompt}>
        Install App
      </Button>{' '}
    </Typography>
  );
};

export const InstallPrompt = InstallPromptButton;
