const SET_RESCHEDULE_CLEAR = 'SET_RESCHEDULE_CLEAR';
const SET_RESCHEDULE_WORKING = 'SET_RESCHEDULE_WORKING';
const SET_RESCHEDULE_CONFIRMED = 'SET_RESCHEDULE_CONFIRMED';

const initialState = {
  working: false,
  confirmed: null,
};

export default (previousState = initialState, { type, payload }) => {
  if (type === SET_RESCHEDULE_CLEAR) {
    return initialState;
  }

  if (type === SET_RESCHEDULE_WORKING) {
    return {
      ...previousState,
      working: payload,
    };
  }

  if (type === SET_RESCHEDULE_CONFIRMED) {
    return {
      ...previousState,
      confirmed: payload,
    };
  }

  return previousState;
};

export const rescheduleClear = (payload) => {
  return {
    type: SET_RESCHEDULE_CLEAR,
    payload,
  };
};

export const rescheduleWorking = (payload) => {
  return {
    type: SET_RESCHEDULE_WORKING,
    payload,
  };
};

export const rescheduleConfirmed = (payload) => {
  return {
    type: SET_RESCHEDULE_CONFIRMED,
    payload,
  };
};
