import reschedule from './rescheduleReducer';
import feedback from './feedbackReducer';
import user from './userReducer';
//import buy from './buyReducer';

export default {
  reschedule,
  feedback,
  user,
  //buy,
};
