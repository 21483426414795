import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// https://marmelab.com/react-admin/doc/3.19/Theming.html#replacing-the-appbar
//import { AppBar } from 'react-admin';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { setSidebarVisibility } from 'react-admin';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { MyUserMenu } from './MyUserMenu';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom'; //import Link from '@material-ui/core/Link';
import logo from './logo.svg';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';

export const useStyles = makeStyles({
  appBar: {
    bottom: 0,
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: '40px',
    color: '#fff',
    background: '#fff',
    borderRadius: 20,
    marginLeft: 10,
    marginRight: 20,
  },
});

export const MyAppBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  //useEffect(() => { dispatch(setSidebarVisibility(true)); }, [setSidebarVisibility]);

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(setSidebarVisibility(!open))}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/">
          <img src={logo} alt="logo" className={classes.logo} />
        </Link>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        {/* <Logo /> */}
        <span className={classes.spacer} />
        <Link
          to="/search"
          onClick={(e) => {
            if ((top.location.pathname || '').startsWith('/search')) {
              e.preventDefault();
              // need to be rewritten
              // https://bobbyhadz.com/blog/react-focus-input-on-element
              document.querySelector('input').focus();
            }
          }}
        >
          <Fab color="secondary" aria-label="add" className={classes.fabButton}>
            <SearchIcon />
          </Fab>
        </Link>
      </Toolbar>
    </AppBar>
  );
};
