import React from 'react';
import { Notification } from 'react-admin';

export const MyNotification = (props) => (
  <Notification {...props} autoHideDuration={5000} />
);

// see: index.js:1 Warning: Failed prop type: The prop `onExited` of `ForwardRef(Snackbar)` is deprecated. Use the `TransitionProps` prop instead.
// https://stackoverflow.com/questions/62461781/material-ui-how-to-change-transition-of-customized-snackbar-to-slide
//export const Alert = (props) => {
//  return <MuiAlert elevation={6} variant="filled" {...props} />;
//};
