/*

import { createBrowserHistory } from 'history';
// import Config from './config'

// console.log('HISTORY', {env:process.env,Config})

// https://github.com/marmelab/react-admin/pull/1849#issuecomment-391507494
// https://stackoverflow.com/a/50497983
export default createBrowserHistory({
  window,
  // basename: Config.base || './',
  // basename: process.env.public_url,
  // basename: context.BASE_PATH,
});

*/

import { createBrowserHistory } from 'history';
import ReactGA from './analytics';

// create the history
const history = createBrowserHistory({
  window,
});

// listen for a route change and record page view
history.listen((location) => {
  console.log('history.location', location.pathname);
  ReactGA.pageview(location.pathname); //, ['default', 'fwxcms']);
});

export default history;
