import React, { forwardRef } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  useTranslate,
  useQueryWithStore, // UseDataProvider,
  UserMenu,
  MenuItemLink,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Token from 'lib/token';

import { withStyles } from '@material-ui/core';
//import MenuItem from '@material-ui/core/MenuItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemText from '@material-ui/core/ListItemText';
import { InstallPromptMenuItem } from 'components/InstallPrompt';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
  // The key `root` provided to the classes prop is not implemented in undefined.
  // You can only override one of the following: user,userButton,avatar.
  //root: {
  //  width: 300,
  //  background: 'red',
  //  border: 'blue solid 1px',
  //},
});

const MyCustomIcon = connect()(
  (
    // eslint-disable-next-line
    { img, dispatch }
  ) => {
    const classes = useStyles();

    return <Avatar className={classes.avatar} src={img} />;
  }
);

export const SettingsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/settings"
      primaryText={translate('ra.menu.settings', { _: 'Settings' })}
      leftIcon={<SettingsIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

export const ShortcutsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/contact"
      primaryText={translate('ra.menu.contact', { _: 'Contact' })}
      leftIcon={<EmailIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

export const SearchMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/search"
      primaryText={translate('ra.menu.search', { _: 'Search' })}
      leftIcon={<SearchIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

export const MyUserMenu = withStyles({})(
  ({
    //onMenuClick = () => {},
    ...props
  }) => {
    const classes = useStyles();

    // Get user from state, this should be enough
    const userFromState = useSelector((state) => state.user);
    // But can't figure out how to dispatch yet, so, do it the long way
    const { u: userId } = Token.get();
    // Console.log('MyCustomIcon', {userFromState, userId, props});
    const { loading, error, data } = useQueryWithStore({
      type: 'getOne',
      resource: 'profile',
      payload: { id: userId },
    });

    if (loading) return ''; // <Loading />;
    if (error) return ''; // <Error />;
    if (!data) return null;

    const md5 = window.CryptoJS.MD5(data.email).toString();
    const img = `https://www.gravatar.com/avatar/${md5}`;

    console.log('MyCustomIcon state', {
      data,
      md5,
      img,
      userFromState,
      loading,
      error,
    });

    return (
      <UserMenu classes={classes} icon={<MyCustomIcon img={img} />} {...props}>
        <InstallPromptMenuItem />
        {/* <MenuItem>Hi {data.name}</MenuItem> */}
        {/* <SettingsMenu /> */}
        <ShortcutsMenu />
        <SearchMenu />
      </UserMenu>
    );
  }
);
